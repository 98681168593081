import { defineStore } from 'pinia';
import { getInitialEntityState } from '@/store/common/entities-state';
import { createEntityActions } from '@/store/common/entities-actions';
import { Collection } from '@/store/common/collections';
import { createEntityGetters } from '@/store/common/entities-getters';
import { Voucher } from '@/model/voucher';
import voucherService from '@/services/voucher.service';
import { AlertType, useUiStore } from '@/store/ui-store';
import I18n from '@/config/i18n';

const initialEntityState = {
  ...getInitialEntityState<Voucher>()
};

export const useVouchersStore = defineStore('vouchers', {
  state: () => initialEntityState,
  getters: {
    ...createEntityGetters<Voucher>()
  },
  actions: {
    ...createEntityActions<Voucher>(Collection.VOUCHERS, initialEntityState),
    async delete(uuid: string) {
      const uiStore = useUiStore();
      try {
        await voucherService.delete(uuid);
        uiStore.alert(I18n.t('voucher.deletionConfirmed'), AlertType.SUCCESS);
        this.findAllPaginated({
          page: this.pagination.current_page,
          force: true
        });
      } catch (exception) {
        uiStore.alert(exception.message);
      }
    }
  }
});
