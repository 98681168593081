














import Vue, { PropType } from 'vue';
import { TranslateResult } from 'vue-i18n';
import i18n from '@/config/i18n';

export default Vue.extend({
  props: {
    icon: {
      type: String,
      required: true
    },
    marginTop: {
      type: Boolean,
      default: true // Pour une liste tableau (mettre à false si dans un .card)
    },
    text: {
      type: String as PropType<TranslateResult>,
      default: i18n.t('emptyDataset.default')
    },
    size: {
      type: Number,
      default: 4
    }
  }
});
