
































import TextInput from '@/components/shared/forms/TextInput.vue';
import DateInput from '@/components/shared/forms/DateInput.vue';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useVouchersStore } from '@/store/vouchers-store';
import { Voucher } from '@/model/voucher';
import { API_DATE_FORMAT } from '@/constants';

const DEFAULT_VOUCHER = {
  face_value: 0,
  validity: moment()
    .add(1, 'year')
    .toDate()
};

export default Vue.extend({
  components: {
    TextInput,
    DateInput
  },
  data() {
    return {
      voucher: cloneDeep(DEFAULT_VOUCHER) as Voucher
    };
  },
  computed: {
    ...mapStores(useVouchersStore),
    saving(): boolean {
      return this.vouchersStore.saving;
    },
    validationErrors(): {} {
      return this.vouchersStore.validationErrors;
    },
    tomorrow(): Date {
      return moment()
        .add(1, 'day')
        .toDate();
    },
    dataForApi(): any {
      const date =
        this.voucher.validity === moment().format('YYYY-MM-DD')
          ? null
          : moment(this.voucher.validity).format(API_DATE_FORMAT);
      return {
        face_value: this.voucher.face_value,
        validity: date
      };
    }
  },
  methods: {
    async add(bvModalEvt) {
      bvModalEvt.preventDefault();
      const addedVoucher: Voucher = await this.vouchersStore.create(
        this.dataForApi
      );
      if (addedVoucher !== undefined) {
        this.voucher = cloneDeep(DEFAULT_VOUCHER);
        this.$emit('added', addedVoucher);
      }
    }
  }
});
