

















import Vue from 'vue';

export default Vue.extend({
  props: {
    marginTop: {
      type: Boolean,
      default: true // Pour une liste tableau (mettre à false si dans un .card)
    },
    text: {
      type: String,
      default: 'Une erreur est survenue'
    }
  },
  methods: {
    onClickOnRetry() {
      this.$emit('retry');
    }
  }
});
