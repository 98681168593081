

























































































































































import Search from '@/components/shared/Search.vue';
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import EmptyDataset from '@/components/shared/EmptyDataset.vue';
import FetchFailure from '@/components/shared/FetchFailure.vue';
import VoucherAddModal from '@/components/vouchers/VoucherAddModal.vue';
import moment from 'moment';
import ConfirmModal, {
  IConfirmModal
} from '@/components/shared/ConfirmModal.vue';
import Vue from 'vue';
import { Voucher } from '@/model/voucher';
import { mapStores } from 'pinia';
import { useVouchersStore } from '@/store/vouchers-store';

export default Vue.extend({
  components: {
    Search,
    LoadingIndicator,
    EmptyDataset,
    FetchFailure,
    VoucherAddModal,
    ConfirmModal
  },
  data() {
    return {
      searchTerms: '',
      currentPage: 1,
      perPage: 25,
      addedVoucher: {} as Voucher,
      deleteVoucherModal: {} as IConfirmModal,
      isDeleting: false
    };
  },
  computed: {
    ...mapStores(useVouchersStore),
    vouchers(): Voucher[] {
      return this.vouchersStore.entities;
    },
    rows(): number {
      return this.vouchersStore.pagination.count;
    },
    pageCount(): number {
      return this.vouchersStore.pagination.page_count;
    },
    isFetching(): boolean {
      return this.vouchersStore.fetching;
    },
    errorOccurred(): boolean {
      return this.vouchersStore.failure;
    }
  },
  mounted() {
    this.deleteVoucherModal = (this.$refs
      .deleteVoucherModal as unknown) as IConfirmModal;
    this.fetchVouchers();
  },
  methods: {
    getValidity(index: number) {
      return this.vouchers[index].validity === null
        ? 'Libre'
        : moment(this.vouchers[index].validity).format('DD.MM.YYYY');
    },
    fetchVouchers() {
      this.vouchersStore.findAllPaginated({
        page: this.currentPage,
        force: true,
        extraQueryParams: { search: this.searchTerms.toUpperCase() }
      });
    },
    search(value: string) {
      this.currentPage = 1;
      this.searchTerms = value;
      this.fetchVouchers();
    },
    canBeDeleted(voucher: Voucher) {
      return (
        moment().diff(moment(voucher.created), 'minutes') < 60 &&
        voucher.face_value === voucher.balance
      );
    },
    showUuid(addedVoucher: Voucher) {
      this.addedVoucher = addedVoucher;
      this.$bvModal.show('showUuidModal');
    },
    openDeleteModal(uuid: string) {
      this.deleteVoucherModal.openModal(
        this.$i18n.t('voucher.confirmDelete', { uuid: uuid }),
        {
          okTitle: this.$i18n.t('button.delete'),
          okVariant: 'danger',
          cancelTitle: this.$i18n.t('button.cancel')
        },
        uuid
      );
    },
    async deleteVoucherFromUuid(uuid: string) {
      this.deleteVoucherModal.sendingData = true;
      await this.vouchersStore.delete(uuid);
      this.deleteVoucherModal.sendingData = false;
      this.deleteVoucherModal.closeModal();
    }
  }
});
